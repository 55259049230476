import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { IAtmByFederal } from '../models/iatm-byfederal';
import { IHtmlContent } from '../models/ihtml-content';
import { INodeLocation, IOperator } from '../models/inode-location';
import { ByFederalMapper } from '../utils/mappers/byFederalMapper';
import { StoreRocketMapper } from '../utils/mappers/storerocket';
import { IAtmStoreRocket } from '../models/iatm-store-rocket';
import loadable from '@loadable/component';

export interface IMiningPageTemplate {
  data: {
    contentfulMiningPage: {
      title: IHtmlContent;
      byteFederalMetadata: IOperator;
      storeRocketMetadata: IOperator;
      calculatorFields: any;
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allBytefederalJson: {
      totalCount: number;
      nodes: Array<IAtmByFederal>;
    };
    allStorerocketJson: {
      nodes: Array<IAtmStoreRocket>;
    };
  };
}

class MiningPageTemplate extends React.Component<IMiningPageTemplate> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const byteFederalMetadata =
      this.props?.data?.contentfulMiningPage?.byteFederalMetadata;

    const storerocketMetadata =
      this.props?.data?.contentfulMiningPage?.storeRocketMetadata;

    const byFederal: INodeLocation[] = ByFederalMapper(
      this.props.data?.allBytefederalJson?.nodes,
      byteFederalMetadata
    );

    const storerocket: INodeLocation[] = StoreRocketMapper(
      this.props.data?.allStorerocketJson?.nodes,
      storerocketMetadata
    );

    this.setState({ locations: [...byFederal, ...storerocket] });
  }

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const MiningContainer = loadable(() => import ('../components/mining-page/mining-container/mining-container'));
    const MiningHeader = loadable(() => import ('../components/mining-page/mining-header/mining-header'));
    const TransactionSection = loadable(() => import ('../components/country-page/transaction-section/transaction-section'));

    const cryptoCards = get(this, 'props.data.allContentfulJourneyCards.nodes');
    const title = this.props.data.contentfulMiningPage?.title;
    const dataFields = this.props.data.contentfulMiningPage?.calculatorFields;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    return (
      <Layout className="strawberry-style" footer={footerData} header={headerData}>
          <div className="mining-page">
          <MiningHeader title={title}/>
          <div style={{ background: '#fff' }}>
          <Helmet title={`Bitcoin Mining | ${siteTitle}`} />
            <MiningContainer dataFields={dataFields}/>
            <TransactionSection cryptoCardsData={cryptoCards}/>
          </div>
        </div>
      </Layout>
    );
  }
}
export default MiningPageTemplate;

export const pageQuery = graphql`
  query miningPage {
    site {
      siteMetadata {
        title
      }
    }
    contentfulMiningPage {
      title{
        childMarkdownRemark {
          html
        }
      }
      calculatorFields{
        bitcoinValue {
          title
          tooltip
        }
        hashRate {
          title
          tooltip
        }
        elecComsuption {
          title
          tooltip
        }
        elecCost {
          title
          tooltip
        }
        hashRateGrowth
      }
      byteFederalMetadata {
        email
        phoneNumber
        website
        operator
      }
      storeRocketMetadata {
        email
        phoneNumber
        website
        operator
      }
    }
    allBytefederalJson(filter: { is_enabled: { eq: "true" } }) {
      totalCount
      nodes {
        id
        City
        Name
        Photo_URL
        State
        Street
        color
        currency_code
        enable_sell
        is_enabled
        last_seen
        latitud
        longitude
        operator_name
        operator_website
        prices {
          bitcoin_buy_fee
          bitcoin_buy_flat_fee
          bitcoin_buy_price
          bitcoin_market_feed
          bitcoin_market_price
          bitcoin_sell_fee
          bitcoin_sell_flat_fee
          bitcoin_sell_price
        }
      }
    }
    allStorerocketJson {
      nodes {
        locations {
          id
          address
          country
          cover_image
          hours {
            fri
            mon
            sat
            sun
            thu
            tue
            wed
          }
          lat
          lng
          name
          phone
          url
          email
          visible
        }
      }
    }
    allContentfulJourneyCards {
      totalCount
      nodes {
        id
        createdAt
        enabled
        linkText
        linkUrl
        title
        paragraph {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
